import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import Button from "../../Button";
import Card from "../../Cards/index";
import ConsultationByPeriod from "../../ConsultationByPeriod";
import InputSearch from "../../Input/InputSearch";
import Filters from "../../Filters";
import ProcessInfo from "../../ProcessInfo";
import { useRef } from "react";
import "./style.css";
import { FaQuestionCircle } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";

const Submenu = ({
    processQty = {},
    onChangeSearchString = () => {},
    type,
    setPeriodSchedule,
    period,
    checkedBtnIgnore = true,
    setCheckedBtnIgnore = () => {},
    isLoading,
}) => {
    const [actionFilter, setActionFilter] = useState(false);
    const lastChangeSearchString = useRef();
    const filter = useSelector(state => state.filter.value);

    const handleChange = () => {
        setCheckedBtnIgnore(!checkedBtnIgnore);
    };

    return (
        <>
            <Card className="flex justify-between lg:p-3 flex-wrap">
                {type?.filters && (
                    <div className="flex flex-wrap items-center mb-2 lg:m-0">
                        <Button className="mr-4 font-medium h-10" onClick={() => setActionFilter(!actionFilter)}>
                            filtros <FiChevronRight size="1.1rem" />
                            {!!Object.values(filter).length && (
                                <div className="self-start rounded-full w-2 h-2 bg-rosa_oficial" />
                            )}
                        </Button>

                        {!setPeriodSchedule && <ConsultationByPeriod />}

                        {type?.infos && (
                            <div className="w-56 flex flex-shrink-0 justify-center ml-5">
                                <ProcessInfo information={processQty} />
                            </div>
                        )}
                    </div>
                )}
                {setPeriodSchedule && (
                    <div className="flex xs:w-full lg:w-1/2 items-center mb-2">
                        <div className="flex text-roxo_oficial text-lg items-center">
                            <div className="cursor-pointer" onClick={() => setPeriodSchedule(period.previous)}>
                                <IoIosArrowBack size="1.3em" />
                            </div>

                            <h3 className="text-roxo_oficial mx-4 ">{period.formatted}</h3>

                            <div className="cursor-pointer" onClick={() => setPeriodSchedule(period.next)}>
                                <IoIosArrowForward size="1.3em" />
                            </div>
                        </div>
                    </div>
                )}

                {(type?.search || type?.shortcut) && (
                    <div className="flex md:w-4/12 w-full items-center mobile-vertical">
                        {isLoading ? (
                            <div className="flex items-stretch w-full p-2 border border-r-0 border-C2">
                                <ClipLoader size={20} />
                                <span className="ml-2">Carregando...</span>
                            </div>
                        ) : (
                            <InputSearch
                                placeholder="buscar por processo, empresa ou ref."
                                onChange={e => {
                                    if (!type?.shortcut) {
                                        clearTimeout(lastChangeSearchString.current);
                                        const { value } = e.target;
                                        lastChangeSearchString.current = setTimeout(() => {
                                            onChangeSearchString(value);
                                        }, 3000);
                                        window.localStorage.setItem("process-filter", value);
                                    }
                                }}
                                onSend={e => {
                                    if (type?.shortcut) {
                                        if (e.key === "Enter") {
                                            const { value } = e.target;
                                            onChangeSearchString(value);
                                            window.localStorage.setItem("process-filter", value);
                                        }
                                    }
                                }}
                                value={type?.searchString}
                                autoComplete={type?.shortcut ? "off" : "on"}
                            />
                        )}
                        {type?.btnIgnore && (
                            <label className="flex items-center btn-ignore">
                                <input
                                    type="checkbox"
                                    name="ignore-filter"
                                    checked={checkedBtnIgnore}
                                    onChange={handleChange}
                                />
                                <span className="check-box-effect"></span>
                                <span className="font-normal text-sm">Buscar por texto em todos os processos</span>
                                <div className="tolltip">
                                    <FaQuestionCircle />
                                    <span>
                                        Quando este campo estiver selecionado, os Filtros de período, Etapa, Canal,
                                        Modalidade e Tipo de processo serão ignorados ao digitar na barra de busca
                                    </span>
                                </div>
                            </label>
                        )}
                    </div>
                )}

                {actionFilter && <Filters setActionFilter={setActionFilter} actionFilter={actionFilter} />}
            </Card>
        </>
    );
};
export default Submenu;
