import React from "react";
import classnames from "classnames";

const Kanban = ({ title, subtitle, isFeatured, children, className }) => {
    return (
        <div className={classnames("p-2", className)}>
            <div className="font-bold text-C6 lg:h-14 xl:h-auto">
                {title}
                {subtitle && <small className="d-hidden d-sm-inline ml-2">({subtitle})</small>}
                {isFeatured ? (
                    <div className="border-b-2 border-solid border-roxo_oficial" />
                ) : (
                    <div className="border-b-2 border-solid border-C4" />
                )}
            </div>
            {children}
        </div>
    );
};
export default Kanban;
