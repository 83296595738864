import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { removeFilter, updateFilter } from "../../store/modules/filter/actions";

const Filters = ({ setActionFilter, actionFilter, information }) => {
    const [filters, setFilters] = useState({});
    const [selectedList, setSelectedList] = useState({});

    const subtitles = {
        clients: "Empresa",
        stages: "Etapa",
        channels: "Canal",
        transportModalities: "Modalidade",
        processModalities: "Processo",
    };

    const dispatch = useDispatch();
    const { apiFilter, value: filterRedux } = useSelector(state => state.filter);

    function submitFilter(filter) {
        dispatch(updateFilter(filter));
    }

    function handleFilter(entity, key) {
        if (entity instanceof Array) {
            if (!selectedList[key]) {
                selectedList[key] = [];
            }

            let identifiers = [];
            entity.map(e => {
                identifiers.push(e.identifier);
                e.children.length &&
                    e.children.map(c => {
                        identifiers.push(c.identifier);
                    });
            });

            if (!selectedList[key].length) {
                delete selectedList[key];
            }

            selectedList[key] = identifiers;

            submitFilter(selectedList);
        } else {
            entity.checked = !entity.checked;
            let value = selectedList[key];

            if (entity.checked) {
                if (!value) {
                    value = [];
                }
                value = [...value, entity.identifier];
            } else {
                value = value.filter(f => f !== entity.identifier);
            }

            let auxFilter = {};
            if (!value.length) {
                delete selectedList[key];
                auxFilter = { ...selectedList };
            } else {
                auxFilter = { ...selectedList, [key]: value };
            }
            submitFilter(auxFilter);
        }
    }

    useEffect(() => {
        let mounted = true;
        const auxData = {};

        Object.entries(apiFilter).forEach(([key, filter]) => {
            if (key === "clients") {
                auxData[key] = filter?.map(entity => ({
                    ...entity,
                    value: entity.identifier,
                    label: `${entity.name} - ${entity.documentNumber} ${entity.documentNumber.length === 8 ? " (todas)" : "(" + entity.type + ")"}`,
                    checked: !!filterRedux[key]?.find(arr => entity.identifier === arr),
                }));
            } else {
                auxData[key] = filter?.map(entity => {
                    if (typeof entity === "object") {
                        return {
                            ...entity,
                            checked: !!filterRedux[key]?.find(arr => entity.identifier === arr),
                        };
                    }
                    return {
                        identifier: entity,
                        description: entity,
                        checked: !!filterRedux[key]?.find(arr => entity === arr),
                    };
                });
            }
        });

        if (mounted) {
            setSelectedList(Object.assign({}, filterRedux));
            setFilters(Object.assign({}, auxData));
        }

        return () => {
            mounted = false;
        };

        // void infinity looping (selectedList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiFilter, filterRedux]);

    return (
        <aside style={{ zIndex: 1000 }} className="flex -ml-1 -mt-3 fixed w-screen h-screen">
            <div className="overflow-auto pb-32 py-4 px-6 w-full md:w-1/2 lg:w-1/4 xl:w-2/8 bg-white h-screen shadow-lg z-50  ">
                <div className="flex justify-between items-center mb-3">
                    <h4 className="text-roxo_oficial font-medium ">filtros</h4>
                    <FiChevronLeft
                        size="1.5em"
                        color="#422C76"
                        className="cursor-pointer"
                        onClick={() => setActionFilter(!actionFilter)}
                    />
                </div>
                {!!Object.values(filterRedux).length && (
                    <p onClick={() => dispatch(removeFilter())} className="cursor-pointer underline   text-roxo_oficial">
                        limpar filtros
                    </p>
                )}

                {information && (
                    <>
                        <div>
                            <p className="font-normal">Total de processos: {information.total ? information.total : 0}</p>
                            <p className="font-normal">
                                Mostrando: {information.view ? information.view : 0}
                                {information.currentPage > 1 && <small>(página {information.currentPage})</small>}
                            </p>
                        </div>
                    </>
                )}
                {!Object.values(apiFilter).length ? (
                    <div className="flex flex-col items-center">
                        <ClipLoader size={20} />
                    </div>
                ) : (
                    Object.entries(filters).map(([key, filter]) =>
                        filter.length < 10 && key !== "clients" ? (
                            <div key={key}>
                                <h6 className="mt-4">{subtitles[key]}</h6>

                                {filter.map(entity => (
                                    <div key={entity.identifier} className="w-full">
                                        <label className="text-C6 font-normal text-default">
                                            <input
                                                type="checkbox"
                                                name={`${key}[]`}
                                                key={entity.description}
                                                onChange={() => handleFilter(entity, key)}
                                                checked={entity.checked}
                                            />
                                            <span className="check-box-effect"></span>
                                            {key === "clients"
                                                ? entity.name + " - " + entity.documentNumber
                                                : entity.description}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="w-full" key={key}>
                                <h6 className="mt-4">{subtitles[key]}</h6>
                                <div key={key} className="w-full">
                                    <Select
                                        isMulti
                                        defaultValue={filter.filter(i => i.checked)}
                                        name={key}
                                        placeholder={`todas as (${filter.length})`}
                                        options={filter}
                                        className="basic-multi-select w-full text-sm text-roxo_oficial"
                                        classNamePrefix="select"
                                        onChange={arr => {
                                            handleFilter(arr || [], key);
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    )
                )}
            </div>
            <div
                onClick={() => setActionFilter(!actionFilter)}
                className="w-full h-screen -mt-20 hidden lg:flex bg-transparent pb-24"
            />
        </aside>
    );
};
export default Filters;
