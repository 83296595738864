import styled from "styled-components";

import { colors } from "../../../assets/colors";

export const Container = styled.li`
    display: grid;
    grid-template-columns: 1fr;

    grid-template-rows: 19px 23px;
    grid-template-areas:
        "title"
        "s";

    background: ${colors.C1};
    border-radius: 5px;
    max-width: 100%;
    padding: 4px 6px;
    margin-top: 7px;

    strong {
        display: table-cell;
        grid-area: title;
        color: ${colors.C6};
        font-size: 16px;
    }

    span {
        grid-area: s;
        font-size: 13px;
        color: ${colors.C6};

        overflow: auto;
    }
`;
