import React from "react";

import { colors } from "../../../assets/colors";
import { FaCircle } from "react-icons/fa";
import LogoIcon from "../../../assets/svgs/logo-colorful.inline.svg";
import Notification from "../../../assets/svgs/notifications-purple.inline.svg";
import ChatPurpleIcon from "../../../assets/svgs/chatbox-purple.inline.svg";
import { Container } from "./styles";

function CardKanbanSchedule({ code, category }) {
    return (
        <Container>
            <strong>{code}</strong>
            <span>{category}</span>
        </Container>
    );
}

export default CardKanbanSchedule;
