import React from "react";
import { ButtonTw } from "./styles";
import classnames from "classnames";

const Button = ({ children, className, onClick }) => {
    return (
        <ButtonTw className={classnames("flex items-center", className)} onClick={onClick}>
            {children}
        </ButtonTw>
    );
};

export default Button;
