import styled from "styled-components";
import tw from "twin.macro";

export const ButtonTw = styled.button`
    ${tw`px-2
  py-1
  fill-current 
  rounded-md 
  cursor-pointer 
  border 
  border-roxo_oficial 
  text-roxo_oficial
  outline-none`}
`;

export const ButtonFillTw = styled.button`
    ${tw`px-2
  py-1
  h-8
  rounded-md 
  bg-roxo_oficial
  cursor-pointer 
  text-white 
  font-light 
  outline-none`}
`;
