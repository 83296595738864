import React from "react";
import { GoSearch } from "react-icons/go";

const InputSearch = ({ placeholder, onChange, onSend, value, autoComplete }) => {
    return (
        <div className="flex items-stretch w-full mb-1">
            <div className="flex -mr-px">
                <div className="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-C2 px-3">
                    <GoSearch className="text-roxo_oficial" />
                </div>
            </div>
            <input
                type="text"
                id="search"
                name="search"
                className="outline-none flex-1 border-t border-r border-b h-10 border-C2 rounded rounded-l-none px-3"
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onSend}
                defaultValue={value}
                autoComplete={autoComplete}
            />
        </div>
    );
};
export default InputSearch;
