import React from "react";
const ProcessInfo = ({ information }) => {
    if (information) {
        return (
            <div>
                <p className="font-normal">Total de processos: {information.total ? information.total : 0}</p>
                <p className="font-normal">
                    Mostrando: {information.view ? information.view : 0}
                    {information.pages > 0 && (
                        <small>
                            {" "}
                            (página {information.currentPage} de {information.pages})
                        </small>
                    )}
                </p>
            </div>
        );
    } else {
        return null;
    }
};

export default ProcessInfo;
