import React, { useState } from "react";
import { Link } from "gatsby";
import Kanban from "../../components/Kanban";
import CardSchedule from "../../components/Kanban/CardSchedule";

const WeekRow = ({ list }) => {
    let [isDayOpened, setIsDayOpened] = useState({});

    const openDay = key => {
        let aux = { ...isDayOpened };
        aux[key] = true;

        setIsDayOpened(aux);
    };

    return (
        <>
            {list.map((day, dayKey) => {
                return (
                    <Kanban
                        key={dayKey}
                        title={day.fullFormatted}
                        subtitle={day.status === "present" ? "hoje" : null}
                        isFeatured={day.status === "present"}
                        className="md-vw60 xs:border-r-0 lg:border-r border-C2 w-14pe"
                        style={{
                            width: "14%",
                        }}>
                        {day.events.map((data, eventKey) => {
                            return eventKey <= 9 ? (
                                <Link
                                    key={eventKey}
                                    to={`/processos/detalhes/${data.processIdentifier}`}
                                    state={{ prevPath: window.location.pathname }}>
                                    <CardSchedule code={data.processCode} category={data.description}></CardSchedule>
                                </Link>
                            ) : null;
                        })}
                        {day.events.length > 9 && !isDayOpened[dayKey] && (
                            <button
                                className="px-2 py-1 h-8 rounded-md bg-roxo_5 cursor-pointer text-roxo_oficial outline-none mt-2"
                                onClick={() => openDay(dayKey)}>
                                +{day.events.length - 10}
                            </button>
                        )}
                        {isDayOpened[dayKey] && (
                            <>
                                {day.events.map((data, eventKey) => {
                                    return eventKey > 9 ? (
                                        <Link
                                            key={eventKey}
                                            to={`/processos/detalhes/${data.processIdentifier}`}
                                            state={{ prevPath: window.location.pathname }}>
                                            <CardSchedule code={data.processCode} category={data.description}></CardSchedule>
                                        </Link>
                                    ) : null;
                                })}
                            </>
                        )}
                    </Kanban>
                );
            })}
        </>
    );
};

export default WeekRow;