import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Card from "../../components/Cards";
import WeekRow from "../../components/WeekRow";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Submenu from "../../components/Menus/Submenu";

import requests from "../../services/requests";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const IndexPage = () => {
    // List vars
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [searchString, setSearchString] = useState("");

    const filterInformation = useSelector(state => state.filter.value);

    // Filter vars
    const [startDate, setStartDate] = useState(null);

    const [period, setPeriod] = useState({
        current: "",
        previous: "",
        next: "",
        formatted: "",
    });

    const onChangeSearchString = value => {
        let search = value.trim();
        if (search.length > 2 || searchString.length >= search.length) {
            setSearchString(search);
        }
    };

    // Function to search information via API
    const getInformation = useCallback(() => {
        setIsLoading(true);
        requests
            .calendar({ filters: filterInformation, search: searchString, startsAt: startDate })
            .then(data => {
                setList(data.weeks);

                setPeriod({
                    current: data.meta.start,
                    formatted: data.meta.formatted,
                    previous: data.meta.previous,
                    next: data.meta.next,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [filterInformation, searchString, startDate]);

    useEffect(() => {
        getInformation();
    }, [getInformation]);

    return (
        <Layout>
            <SEO title="Agenda" />
            <Submenu
                period={period}
                setPeriodSchedule={setStartDate}
                onChangeSearchString={onChangeSearchString}
                type={{ filters: true, search: true }}
            />

            {isLoading ? (
                <div className="flex flex-col items-center mt-5">
                    <ClipLoader size={40} loading={true} />
                </div>
            ) : (
                <main>
                    {list &&
                        list.map((week, weekKey) => {
                            return (
                                <div key={weekKey} className="p-4">
                                    <Card>
                                        <h5 className="mb-2 text-roxo_oficial">{week.formatted}</h5>
                                        <div className="border-t border-C2 border-solid pt-4 pb-12 overflow-auto">
                                            <div className="flex xs:flex-wrap lg:flex-no-wrap md-fit justify-between">
                                                <WeekRow list={week.days} />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            );
                        })}
                </main>
            )}
        </Layout>
    );
};

export default IndexPage;
